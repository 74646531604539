<template>
  <b-link class="cardCompany" :href="`/profile/${advisor.username}`">
    <div class="logo">
      <img :src="advisorImage(advisor.freelancerImg)" :alt="advisor.username" />
      <div class="info">
        <h3>{{ advisor.name }}</h3>
        <!-- <div class="type"></div> -->
        <div class="location">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokewidth="{1.5}"
              stroke="currentColor"
              class="icon h-[15px] h-[15px]"
            >
              <path
                strokelinecap="round"
                strokelinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
              <path
                strokelinecap="round"
                strokelinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              ></path>
            </svg>
            <span class="item-located-text" v-if="advisor.nationality">{{
              $store.state.locale.locale == "ar"
                ? advisor.nationality[0].arabicName
                : advisor.nationality[0].name
            }}</span>
          </div>
        </div>

        <div class="ratings">
          <ul class="unstyled-list list-inline">
            <li
              v-for="star in 5"
              :key="star"
              class="ratings-list-item"
              :class="{ 'ml-25': star - 1 }"
            >
              <feather-icon
                icon="StarIcon"
                size="16"
                :class="[
                  { 'fill-current': star <= advisor.ratingNumber },
                  star <= advisor.ratingNumber ? 'text-warning' : 'text-muted',
                ]"
              />
            </li>
          </ul>
        </div>
      </div>

      <div class="ratings">
        <!-- <div class="item-rating">
          <ul class="unstyled-list list-inline">
            <li
              v-for="star in 5"
              :key="star"
              class="ratings-list-item"
              :class="{ 'ml-25': star - 1 }"
            >
              <feather-icon
                icon="StarIcon"
                size="16"
                :class="[
                  { 'fill-current': star <= advisor.ratingNumber },
                  star <= advisor.ratingNumber ? 'text-warning' : 'text-muted',
                ]"
              />
            </li>
          </ul>
        </div> -->
      </div>
    </div>

    <div class="industries">
      <span v-for="(ind, index) in advisor.industries" :key="index">{{
        $store.state.locale.locale == "ar" ? "#" + ind.arabicName + " " : "#" + ind.name + " "
      }}</span>
    </div>

    <div class="meta">
      <ul>
        <li>
          <div class="label">{{ $t("advisor.Skills") }}</div>
          <div class="value">
            <span v-for="(data2, index) in advisor.skills" :key="index" class="">
              {{
                $store.state.locale.locale == "ar" && data2.arabicName !== undefined
                  ? "#" + data2.arabicName
                  : "#" + data2.name
              }}</span
            >
          </div>
        </li>
        <!-- <li>
          <div class="label">{{ $t("investor.Location") }}</div>
          <div class="value">
            <span class=""
              >{{
                $store.state.locale.locale == "ar" &&
                data2.arabicName !== undefined
                  ? "#" + advisor.address[0].arabicName
                  : "#" + advisor.address[0].name
              }}
            </span>
          </div>
        </li> -->
      </ul>
    </div>
    <button class="btn btn-sm btn-primary">{{ $t("profile.Follow") }}</button>
  </b-link>
</template>
<script>
import { BLink } from "bootstrap-vue";
import millify from "millify";
export default {
  components: {
    BLink,
  },
  props: {
    advisor: {
      type: Object,
      default: {},
    },
  },
  methods: {
    advisorImage(data) {
      let imgadvisor = "";

      if (data != null) {
        imgadvisor = data;
      } else {
        imgadvisor = require("@/assets/images/index/freelancer.png");
        // imgadvisor = false;
      }

      return imgadvisor;
    },
  },
};
</script>
<style scoped>
.cardCompany {
  display: block;
  background-color: #fff;
  border-radius: 0.375rem;
  padding: 0.75rem;
  height: 100%;
  border: 1px solid rgb(229, 231, 235);
  -webkit-transition: 0.3s linear all;
}
.cardCompany:hover {
}
.cardCompany .logo {
  display: flex;
  /* height: 85px; */
  align-content: center;
  padding: 0.75rem;
  padding-bottom: 0.5rem;
}
.cardCompany .logo img {
  margin-bottom: 0.5rem;
  display: block;
  height: 100%;
  border-radius: 20px;
  height: 80px;
  width: 80px;
}
.cardCompany .logo .ratings {
  margin-left: auto;
  /* display: flex; */
  align-content: center;
  justify-content: center;
  color: rgb(251 146 60 / 1);
}
.cardCompany .info {
  /* margin: 0.5rem; */
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}
.cardCompany .info h3 {
  margin-bottom: 0px;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(55 65 81 / 1);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .type {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(59 59 59);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.cardCompany .info .location {
  display: flex;
  padding-top: 0.25rem;
  font-size: 0.9rem;
  line-height: 1rem;
  color: rgb(0 93 252);
  align-items: center;
  margin-left: -3px;
}
.cardCompany .info .location svg {
  margin-right: 1px;
  width: 20px;
  height: 20px;
  color: #0b2a7e;
}
.cardCompany .industries {
  display: flex;
  /* padding-left: 0.5rem; */
  padding-right: 0.5rem;
  overflow: hidden;
  position: relative;
}
.cardCompany .industries:after {
  content: " ";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
.cardCompany .industries span {
  margin-right: 0.25rem;
  display: block;
  cursor: pointer;
  background-color: rgb(243 244 246/1);
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  /* color: rgb(107 114 128/1); */
  white-space: nowrap;
  color: #2f2d7e;
  font-size: 13px;
}
.cardCompany .meta {
  margin: 0.5rem 0;
}
.cardCompany .meta ul {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  list-style: none;
}
.cardCompany .meta li {
  display: flex;
  border-bottom: 1px solid rgb(243 244 246 / 1);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.6rem;
  color: rgb(156 163 175 / 1);
  justify-content: space-between;
}
.cardCompany .meta li:first-child {
  min-height: 52px;
}
.cardCompany .meta li:last-child {
  border-bottom: none;
}
.cardCompany .meta li .label {
  width: 150px;
  color: rgb(107 114 128 / 1);
  font-size: 1rem;
}
.cardCompany .meta li .value {
  font-size: 0.9rem;
  font-weight: 500;
  color: #3e64b2;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardCompany .highlights {
  min-height: 64px;
  margin-bottom: 1rem;
  border-top: 1px solid rgb(231 231 231);
}
.cardCompany .highlights span {
  color: #2a2a2a;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 10px;
}
</style>
